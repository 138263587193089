import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    // border: "1px solid red"
  },
});

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([props.min, props.max]);

  const minimum = props.min;
  const maximum = props.max;
  const color = "#5bb39b"

  const handleChangeCommited = (event, newValue) => {
    axios.get('/vremenski-prikaz', {
      headers: {
          'Content-Type': 'application/json',
           Accept: 'application/json'
      },
    params: {
      min: newValue[0],
      max: newValue[1]
    }
  })
  .then(function (response) {
    document.getElementById("timeline_filler").innerHTML = response.data;
  })
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommited}
        valueLabelDisplay="on"
        getAriaValueText={valuetext}
        min={minimum}
        max={maximum}
      />
    </div>
  );
}
