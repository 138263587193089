import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic blockquote | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styleselect bold italic underline blockquote | bullist numlist | link footnotes image media table | code help'

  if(props.minimal)
    toolbar_buttons = 'bold italic'

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
    tinymceScriptSrc="/tinymce5/tinymce.min.js"
     initialValue={props.content}
     disabled={props.disabled}
     init={{
       height: props.height,
       menubar: false,
       entity_encoding : "raw",
       inline: props.minimal,
       file_picker_types: 'image',
       image_title: true,
       image_caption: true,
       relative_urls : false,
       default_link_target:"_blank",
       // automatic_uploads: true,
       images_upload_url: "/admin/tinymce_upload",
       file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },
       formats: {
          subtitle: { block: 'h2' }
        },
        style_formats: [
          { title: 'Podnaslov', format: 'subtitle' },
      ],
       plugins: [
         'image link code paste lists advlist media wordcount table help',

       ],
       external_plugins: {
         'footnotes': '/tinymce_external_plugins/footnotes/tinymce5.x/footnotes/plugin_v2.js'
       },
       // content_css: '/tinymce_custom_v2.css',
       // quickbars_selection_toolbar: props.minimal ? 'bold italic styleselect' : 'bold italic styleselect | quicklink blockquote',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
