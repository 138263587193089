
var map;
var infowindow;
var bounds;
var marker, i;
var markerCluster;
var recenter = false;

import { MarkerClusterer } from "@googlemaps/markerclusterer";

function initMap(recenter=true) {
    var mapElement = document.getElementById('map2');
    if (mapElement) {

      var mapOptions = {
          zoom: 8,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          center: new google.maps.LatLng(45.450490, 15.9889337614),
          styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},
          {"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"on"}]},
          {"featureType":"landscape","elementType":"all","stylers":[{"hue":"#5bb39b"},{"saturation":0},{"lightness":-20},{"visibility":"on"}]},
          {"featureType":"poi","elementType":"geometry","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"off"}]},
          {"featureType":"poi","elementType":"labels","stylers":[{"saturation":-100},{"lightness":0},{"visibility":"off"}]},
          {"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},
          {"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},
          {"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},
          {"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},
          {"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},
          {"featureType":"water","elementType":"geometry","stylers":[{"hue":"#75D8FE"},{"lightness":50},{"saturation":-20}]},
          {"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}],
      };

      map = new google.maps.Map(mapElement, mapOptions);
      infowindow = new google.maps.InfoWindow();
      bounds = new google.maps.LatLngBounds();
      markerCluster = new MarkerClusterer({ map });
      mapMarkersSetup(recenter);
    }
}


function mapMarkersSetup(recenter) {
  var locations = mapMarkersData;
  for (i = 0; i < locations.length; i++) {

    // if(locations[i][6] > 0) {
    //   circle = new google.maps.Circle({
    //     strokeColor: "#734848",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 1,
    //     fillColor: "#734848",
    //     fillOpacity: 0.2,
    //     map,
    //     center: new google.maps.LatLng(locations[i][1], locations[i][2]),
    //     // radius: locations[i][6],
    //   });
    // }

    marker = new google.maps.Marker({
      position: new google.maps.LatLng(locations[i][1], locations[i][2]),
      map: map,
      icon: '/marker2.png',
      title: locations[i][5]
    });
    markerCluster.addMarker(marker);
    bounds.extend(marker.position);
    google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {
        infowindow.setContent("<a href='"+locations[i][3]+"'>"+locations[i][0]+"</a>");
        infowindow.open(map, marker);
      }
    })(marker, i));
  }
  if(recenter) {
    map.fitBounds(bounds);
  }
}

window.onload = function(e){
  initMap(recenter);
}
