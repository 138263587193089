require('lightbox2')
require('jquery-ui-bundle')
require('jquery.cookie')

$(document).ready(function() {

  $('.cookies button').click(function(e) {
    $.cookie('e-erim_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies').fadeOut("slow");
  })

  $(".select_box_title").click(function () {
    $(".select_box_title").toggleClass('active');
    $(".select_menu").toggleClass('dropdown');
  });


  // Hamburger icon and main navigation
  $(".hamburger").click(function () {
    $("body").toggleClass('navigation_open');
    $(".hamburger").toggleClass('is-active');
  });

  // // choose locations_menu
  // $(".loc_title").click(function () {
  //   $(".location_switch").toggleClass("active");
  // });

  // Modal 
  $(".modal_button .cite_button").click(function () {
    $("body").addClass('modal_open');
    $(".modal_button").addClass('is-active');
  });

  $(".modal_button .close").click(function () {
    $("body").removeClass('modal_open');
    $(".modal_button").removeClass('is-active');
  });
});
