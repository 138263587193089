anychart.onDocumentReady(function () {
		var network_url = $('#network_container').data('network-url');
		anychart.data.loadJsonFile(network_url, function (data) {
		var chart = anychart.graph(data);
			
		// prevent the default behavior of the chart
		// chart.interactivity().enabled(false);

		// add a zoom control panel
		var zoomController = anychart.ui.zoom();
		zoomController.target(chart);
		zoomController.render();

		chart.edges().normal().stroke("#5bb39b", 1, "5 2", "round");
		chart.edges().hovered().stroke("#5bb39b", 2, "5 2", "round");
		chart.edges().selected().stroke("#5bb39b", 2);
		chart.edges().tooltip().format("{%from} -> {%to}");
		
		chart.nodes().normal().fill("#734848");
		chart.nodes().hovered().fill("#3d1919");

		// chart.nodes().labels().enabled(true);
		// chart.nodes().labels().fontSize(12);
		// chart.nodes().shape("box");
		// chart.nodes().width(190);
		// chart.nodes().height(50);
		
		// chart.nodes().labels().useHtml(true);
		// chart.nodes().labels().format(
		// 	"<a href='/{%id}'>{%id}</a>"
		// );
		
		// chart.tooltip().useHtml(true);
		// chart.tooltip().format(
		// 	"<a href='/{%id}'>{%id}</a>"
		// );

		
			chart.listen('click', function (e) {
				var tag = e.domTarget.tag;
				if (tag) {					
					if (tag.type === 'node') {
						var url;
						for (var i = 0; i < data.nodes.length; i++) {
							if (data.nodes[i].id === tag.id) {
								url = data.nodes[i].url;
								break;
							}
						}
						window.open('/pojam/' + url, '_blank');
					}
				}
			});	
		
		// chart.move(-20, 0);  
		chart.container("network_container").draw();
		});
});
